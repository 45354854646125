import React from "react";
import styled from "styled-components";

import { PrimaryButton } from "./Buttons";
import { H3, P } from "./Texts";

import tryImage from "../assets/try-today-image.png";
import tryImageMobile from "../assets/try-today-image-mobile.png";

const TrySection = styled.section`
  background-image: url(${tryImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;

  @media (max-width: 600px) {
    background-image: url(${tryImageMobile});
  }
`;

const CtaSection = () => {
  return (
    <section className="container mb-20">
      <TrySection className="py-6 md:py-10 lg:py-20 text-center">
        <H3 color="#fff" fontSize="40px" fontFamily="semi" className="mb-6 lg:mb-12">
          Try Social Safety today!
        </H3>
        <P fontSize="18px" className="mb-6 w-4/5 md:w-3/4 lg:w-2/5 mx-auto" color="#fff">
          Social Safety Net will become the universal Back-end Technology for public good.
        </P>
        <a href="https://socialsafety.net/" target="_blank" rel="noreferrer">
          <PrimaryButton.White p="20px 24px" w="253px">
            Get started
          </PrimaryButton.White>
        </a>
      </TrySection>
    </section>
  );
};

export default CtaSection;
