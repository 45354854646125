import React, { useState, createContext } from "react";
import t from "prop-types";

export const DrawerContext = createContext();

const DrawerProvider = ({ children }) => {
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerActive(!isDrawerActive);
  };

  return (
    <DrawerContext.Provider value={{ toggleDrawer, isDrawerActive }}>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerProvider.propTypes = {
  children: t.node.isRequired,
};

export default DrawerProvider;
