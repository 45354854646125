import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
// import "react-widgets/dist/css/react-widgets.css";
// import "react-toastify/dist/ReactToastify.css";

// import StoreProvider from "./store/Store";
import { ThemeProvider } from "./lib/themeContext";
import LandingPage from "./pages/LandingPage";
import OrganizationsPage from "./pages/OrganizationsPage";
import DataLabsPage from "./pages/DataLabsPage";
// import SearchPage from "./pages/SearchPage";
import InitiativesPage from "./pages/InitiativesPage";
import NotFoundPage from "./pages/NotFoundPage";
import DrawerProvider from "./lib/drawerContext";
// import { AuthProvider } from "./store/AuthStore";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider>
        <DrawerProvider>
          {/* <StoreProvider> */}
          <Switch>
            <Route exact path="/initiatives">
              <InitiativesPage />
            </Route>
            {/* 
          <Route exact path="/search">
            <SearchPage />
          </Route> */}

            <Route exact path="/datalabs">
              <DataLabsPage />
            </Route>

            <Route exact path="/organizations">
              <OrganizationsPage />
            </Route>

            <Route exact path="/">
              <LandingPage />
            </Route>

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </DrawerProvider>
      </ThemeProvider>
      {/* </StoreProvider> */}
    </Router>
  );
}

export default App;
