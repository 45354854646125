import styled from "styled-components";

const Badge = styled.div`
  border-radius: ${(props) => props.borderRadius || "13px"};
  padding: ${(props) => props.p || "3px 13px"};
  background: ${(props) => props.bg || "#D0F0FD"};
  font-size: ${(props) => props.fontSize || "inherit"};
  color: ${(props) => props.color || "inherit"};
  width: ${(props) => props.w};
`;

export const BadgeSpan = styled.span`
  display: inline-block;
  padding: 4px 8px;
  background: #00b05f;
  border-radius: 3px;
  font-size: 16px;
  color: ${(props) => props.color || "#ffffff"};
`;

export default Badge;
