import React, { useState } from "react";
import t from "prop-types";
import styled from "styled-components";
import * as Icon from "react-feather";

import heroImg from "../assets/hero-image.png";
import organizationFeatureImg from "../assets/ssn-organizations.png";
import searchFeatureImg from "../assets/ssn-search.png";
import datalabFeatureImg from "../assets/ssn-datalabs.png";
import organizationIcon from "../assets/Organizations.svg";
import initiativesIcon from "../assets/Initiatives.svg";
import datalabsIcon from "../assets/datalabs.svg";
import searchIcon from "../assets/Search.svg";
import happensImg from "../assets/happens-next.png";
import returnIcon from "../assets/Return.svg";

import Header from "../components/Headers";
import { H1, H2, H3, H4, P, TextLink } from "../components/Texts";
// import { OutlineButton, PrimaryButton } from "../components/Buttons";
import { Link } from "react-router-dom";
import Footer from "../components/Footers";
import CtaSection from "../components/CtaSection";
import Drawer from "../components/Drawer";
// import DrawerProvider from "../lib/drawerContext";

const Hero = styled.section`
  background: #06002f;
  // height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const HeroImage = styled.img`
  transform: translateX(-8px);
  // margin-bottom: -20em;
  height: 100vh;
  // object-fit: none;
`;

const FeatureCardStyle = styled.div`
  @media (max-width: 600px) {
    img {
      width: 54px;
      height: 56px;
    }
  }
`;

const FeatureCard = ({ icon, heading, body, link, href }) => (
  <FeatureCardStyle className="flex flex-col justify-between h-full">
    <div>
      <img src={icon} alt="" className="mb-8" />
      <H3 className="mb-6" color="#333">
        {heading}
      </H3>
      <P fontSize="18px" className="mb-6" color="#828282">
        {body}
      </P>
      {href && (
        <a href={href} target="_blank" rel="noreferrer" className="block">
          <TextLink color="#442ED0" fontFamily="medium" className="flex items-center">
            Learn more
            <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
          </TextLink>
        </a>
      )}
    </div>
    {link && (
      <Link to={link}>
        <TextLink color="#442ED0" fontFamily="medium" className="flex items-center">
          Learn more
          <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
        </TextLink>
      </Link>
    )}
    {/* {href && (
      <a href={href} target="_blank" rel="noreferrer" className="block">
        <TextLink color="#442ED0" fontFamily="medium" className="flex items-center">
          Learn more
          <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
        </TextLink>
      </a>
    )} */}
  </FeatureCardStyle>
);

FeatureCard.propTypes = {
  icon: t.string,
  heading: t.string.isRequired,
  body: t.string.isRequired,
  link: t.string,
  href: t.string,
};

const SearchWidget = styled.div`
  background: #ffffff;

  box-shadow: 0px 7px 50px rgba(12, 2, 77, 0.1);
  border-radius: 81px;

  transform: translateY(-50%);
`;

const FeatureLink = styled.li`
  background: ${(props) => (props.active ? "#EAE7FE" : "transparent")};
  border: 1px solid #cecbe2;
  border-radius: 36px;
  padding: 8px 16px;
`;

const LandingPage = (props) => {
  const [feature, setFeature] = useState("organizations");

  return (
    <main>
      <Header />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="max-h-screen w-full flex justify-between flex-wrap-reverse">
        <div className="pl-4 lg:pl-24 pr-4 md:pr-0 self-center pt-20 lg:w-1/2 md:w-8/12">
          <H1 color="#ffffff" className="mb-6 md:mb-10">
            The Public Good Platform.
          </H1>
          <H4 color="#ffffff" fontFamily="regular" className="md:w-5/6 mb-10" fontSize="18px">
            Finding the resources you need for the people you love, by providing information on
            local social services.
          </H4>
          {/* <div className="flex items-center space-x-6">
              <a href="/suggest" className="mr-6">
                <OutlineButton color="#ffffff" p="18px 24px" w="161px">
                  Sign In
                </OutlineButton>
              </a>
              <a href="/createAccount" className="mr-6">
                <PrimaryButton p="18px 24px" w="161px">
                  Create Account
                </PrimaryButton>
              </a>
            </div> */}
        </div>
        <div className="hidden lg:block md:w-1/2 self-end pr-2">
          <HeroImage src={heroImg} alt="" />
        </div>
      </Hero>
      {/* What is social safety net */}
      <div className="container">
        <SearchWidget className="py-6 px-4 md:px-14 z-10 flex items-center justify-between">
          <P fontSize="32px" smallSize="20px">
            <span className="hidden lg:inline">How can we help you?</span>
            <a
              href="https://socialsafety.net/"
              target="_blank"
              rel="noreferrer"
              className="ml-2"
              style={{ color: "#442ed0" }}
            >
              Try search for free.
            </a>
          </P>
          <a href="https://socialsafety.net/" target="_blank" rel="noreferrer">
            <Icon.ArrowRight size={32} color="#442ed0" />
          </a>
        </SearchWidget>
      </div>

      <section className="container py-10 lg:py-32 lg:flex">
        <H2
          color="#442ED0"
          fontSize="50px"
          fontFamily="semi"
          lineHeight="64px"
          smLineHeight="32px"
          className="lg:mr-10 lg:w-3/5 mb-6 lg:mb-0"
        >
          Social Safety Net will become the universal Back-end Technology for public good.
        </H2>
        <P color="#828282" fontSize="18px" className="lg:w-2/5 leading-8">
          Social Safety Net streamlines social impact by using AI, ML, and NLP to provide tailored
          information, eligibility, referrals, case management and data on civic, social, and human
          services.
        </P>
      </section>
      <section className="container hidden lg:flex">
        <ul className="flex-shrink-0 mr-10">
          <li
            className="flex items-center cursor-pointer mb-5"
            onClick={() => setFeature("organizations")}
          >
            <P color={feature === "organizations" ? "#442ED0" : "#9390B0"}>SSN for Organizations</P>
            {feature === "organizations" && (
              <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
            )}
          </li>
          {/* <li
              className="flex items-center cursor-pointer mb-5"
              onClick={() => setFeature("initiatives")}
            >
              <P color={feature === "initiatives" ? "#442ED0" : "#9390B0"}>Initiatives</P>
              {feature === "initiatives" && (
                <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
              )}
            </li> */}
          <li
            className="flex items-center cursor-pointer mb-5"
            onClick={() => setFeature("datalabs")}
          >
            <P color={feature === "datalabs" ? "#442ED0" : "#9390B0"}>Data Labs</P>
            {feature === "datalabs" && (
              <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
            )}
          </li>
          <li
            className="flex items-center cursor-pointer mb-5"
            onClick={() => setFeature("search")}
          >
            <P color={feature === "search" ? "#442ED0" : "#9390B0"}>Search</P>
            {feature === "search" && <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />}
          </li>
        </ul>

        <div className="flex-grow -mt-10">
          {feature === "organizations" && <img src={organizationFeatureImg} alt="" />}
          {feature === "datalabs" && <img src={datalabFeatureImg} alt="" />}
          {feature === "search" && <img src={searchFeatureImg} alt="" />}
        </div>
      </section>
      <section className="container lg:hidden">
        <ul className="flex overflow-x-auto mb-6">
          <FeatureLink
            className="flex items-center cursor-pointer mr-4"
            active={feature === "organizations"}
            onClick={() => setFeature("organizations")}
          >
            <P
              className="whitespace-nowrap"
              color={feature === "organizations" ? "#442ED0" : "#9390B0"}
            >
              SSN for Organizations
            </P>
          </FeatureLink>
          {/* <li
              className="flex items-center cursor-pointer mr-4"
              onClick={() => setFeature("initiatives")}
            >
              <P color={feature === "initiatives" ? "#442ED0" : "#9390B0"}>Initiatives</P>
              {feature === "initiatives" && (
                <Icon.ArrowRight color="#442ED0" size={16} className="ml-4" />
              )}
            </li> */}
          <FeatureLink
            className="flex items-center cursor-pointer mr-4"
            active={feature === "datalabs"}
            onClick={() => setFeature("datalabs")}
          >
            <P className="whitespace-nowrap" color={feature === "datalabs" ? "#442ED0" : "#9390B0"}>
              Data Labs
            </P>
          </FeatureLink>
          <FeatureLink
            className="flex items-center cursor-pointer mr-4"
            active={feature === "search"}
            onClick={() => setFeature("search")}
          >
            <P className="whitespace-nowrap" color={feature === "search" ? "#442ED0" : "#9390B0"}>
              Search
            </P>
          </FeatureLink>
        </ul>

        <div className="">
          {feature === "organizations" && <img src={organizationFeatureImg} alt="" />}
          {feature === "datalabs" && <img src={datalabFeatureImg} alt="" />}
          {feature === "search" && <img src={searchFeatureImg} alt="" />}
        </div>
      </section>
      <section className="container pt-32 pb-10 mb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 gap-y-20">
        <FeatureCard
          icon={organizationIcon}
          heading="SSN for Organizations"
          body="We provide a seamless way for organizations to be found, receive and contact referrals, manage cases, and track impact. Organizations can claim a listing if it already exists on SSN or create one from scratch."
          link="/organizations"
        />
        <FeatureCard
          icon={initiativesIcon}
          heading="SSN for Initiatives"
          body="The enterprise tool to combine the power of our search engine, organizations’ toolkits, and data labs to build custom platforms for clients."
          link="/initiatives"
        />
        <FeatureCard
          icon={datalabsIcon}
          heading="Data Labs"
          body="Users can search, analyze, visualize, and perform ML operations on data and export it in various formats (including APIs)."
          link="/datalabs"
        />
        <FeatureCard
          icon={searchIcon}
          heading="Search"
          body="Users can search for social services by uploading their data and specifying their needs. Help is received through an easy match-making process."
          href="https://socialsafety.net/"
        />
        <div className="hidden lg:block col-span-2 row-span-2">
          <img src={happensImg} alt="" className="w-full" />
        </div>
        <div className="place-self-end flex items-start justify-between">
          <img src={returnIcon} alt="" className="mr-10" />
          <div>
            <H3 className="mb-6" color="#0C024D">
              Now that you have your data, what happens next?
            </H3>
            <P fontSize="18px" className="mb-6" color="#828282">
              We provide easy-to-ready data visuals and information so you can understand your
              impact.
            </P>
          </div>
        </div>
        <div className="lg:hidden">
          <img src={happensImg} alt="" className="w-full" />
        </div>
      </section>
      <CtaSection />
      <Footer />
    </main>
  );
};

LandingPage.propTypes = {};

export default LandingPage;
