import React, { useState } from "react";
import t from "prop-types";
import styled from "styled-components";
// import * as Icon from "react-feather";

import heroImg from "../assets/organization-hero-img.png";
import doMoreImg from "../assets/do-more-curly.png";
import listingsIcon from "../assets/Listings.svg";
import receiveIcon from "../assets/receive.svg";
import trackIcon from "../assets/Track.svg";
import dashboardIcon from "../assets/Dashboard.svg";
import shareIcon from "../assets/share.svg";
import appointmentsIcon from "../assets/appointments.svg";
import referralsIcon from "../assets/referrals.svg";
import criteriaIcon from "../assets/criteria.svg";
import searchIcon from "../assets/Search-dark.svg";
import testimonial_1_Img from "../assets/testimonial-1.png";
import testimonial_2_Img from "../assets/testimonial-2.png";
import testimonial_3_Img from "../assets/testimonial-3.png";

import { OrganizationHeader } from "../components/Headers";
import { BigP, H1, H2, H3, H4, P, SmallP } from "../components/Texts";
import { PrimaryButton } from "../components/Buttons";
// import { Link } from "react-router-dom";
import Footer from "../components/Footers";
import CtaSection from "../components/CtaSection";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Drawer from "../components/Drawer";

const Hero = styled.section`
  background: #ffffff;
  // height: 100vh;
  min-height: 100vh;
  // max-height: 100vh;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const FeatureCardStyle = styled.div`
  @media (max-width: 600px) {
    img {
      width: 54px;
      height: 56px;
    }
  }
`;

const FeatureCard = ({ icon, heading, body, link }) => (
  <FeatureCardStyle className="flex flex-col justify-between h-full">
    <div>
      <img src={icon} alt="" className="mb-8" />
      <H3 className="mb-6" color="#fff">
        {heading}
      </H3>
      <P fontSize="18px" className="mb-6" color="#CECBE2">
        {body}
      </P>
    </div>
    {/* <Link to={link}>
      <TextLink color="#fff" fontFamily="medium" className="flex items-center">
        Learn more
        <Icon.ArrowRight color="#fff" size={16} className="ml-4" />
      </TextLink>
    </Link> */}
  </FeatureCardStyle>
);

FeatureCard.propTypes = {
  icon: t.string,
  heading: t.string.isRequired,
  body: t.string.isRequired,
  link: t.string,
};

const SliderStyle = styled.section`
  max-width: 100vh;

  .slide-holder {
    min-height: 415px;
  }

  .slide-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: all 500ms ease;
  }
  .slide-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 500ms ease;
  }
`;

const TestimonialImageStyle = styled.div`
  width: 85px;
  height: 85px;

  @media (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid ${(props) => (props.active ? "#7D6BF1" : "transparent")};
`;

const testimonials = [
  {
    body: "“Social Safety has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who trains many design teams, this makes social safety a clear choice as the industry-standard tool for track and manage cases.”",
    author: "Silvia Bormüller",
    position: "CEO Trainnex",
  },
  {
    body: "“I founded Social Safety Net to be the bridge between good intentions and meaningful outcomes. We can unlock new impact by making finding help as easy as finding lunch.”",
    author: "Julia Muld",
    position: "CEO Pharmatex",
  },
  {
    body: "“Social Safety has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who trains many design teams, this makes social safety a clear choice as the industry-standard tool for track and manage cases.”",
    author: "Kelvin Walsh",
    position: "CTO Lista",
  },
];

const Testimonial = ({ body, author, position }) => (
  <div>
    <BigP
      fontSize="40px"
      smallSize="24px"
      fontFamily="regular"
      lineHeight="48px"
      className="text-center mx-auto md:w-3/4 mb-12"
      color="#442ED0"
    >
      {body}
    </BigP>
    <P fontSize="24px" fontFamily="medium" className="text-center mb-4" color="#0C024D">
      {author}
    </P>
    <SmallP color="#393648" fontSize="16px" className="text-center">
      {position}
    </SmallP>
  </div>
);

Testimonial.propTypes = {
  body: t.string.isRequired,
  author: t.string.isRequired,
  position: t.string.isRequired,
};

const OrganizationsPage = (props) => {
  const [testimonial, setTestimonial] = useState(0);

  return (
    <main>
      <div className="md:hidden">
        <Drawer />
      </div>
      <OrganizationHeader />
      <Hero className="container pt-32">
        <div className="w-full lg:w-2/3 md:mx-auto md:pt-20">
          <H1 color="#442ED0" className="mb-10 text-center">
            A tool for social service providers & non-profit organizations.
          </H1>
          <H4
            color="#0C024D"
            fontFamily="regular"
            className="md:w-5/6 md:mx-auto text-center mb-10"
            fontSize="18px"
          >
            We provide a seamless way for organizations to be found online, recieve referrals,
            manage cases, and track impact!
          </H4>
          <div className="flex items-center justify-center md:space-x-6">
            {/* <a href="/suggest" className="mr-6">
              <OutlineButton color="#442ED0" p="18px 24px" w="161px">
                Sign In
              </OutlineButton>
            </a> */}
            <a
              href="https://organizations.socialsafety.net/"
              target="_blank"
              rel="noopener noreferrer"
              className="md:mr-6"
            >
              <PrimaryButton p="18px 24px" w="161px" smallW="312px">
                Create a Listing
              </PrimaryButton>
            </a>
          </div>
        </div>
        <img src={heroImg} alt="" className="hidden lg:block mx-auto mt-16" />
      </Hero>
      {/* What is social safety net */}
      <section
        className="pl-6 lg:pl-24 py-6 md:py-32 -mt-12 flex"
        style={{ backgroundColor: "#0C024D" }}
      >
        <div className="mr-10 md:w-3/5">
          <H2
            color="#ffffff"
            fontSize="50px"
            fontFamily="semi"
            lineHeight="64px"
            className="w-full mb-14"
          >
            Do more good and serve more people with our online portal.
          </H2>
          <P color="#B4B0D1" fontSize="18px" className="leading-8 md:w-5/6">
            Through Social Safety Net, people in need can connect with service providers. In turn,
            we provide your organization tools to create better, measurable, impact at scale.
          </P>
        </div>
        <img src={doMoreImg} alt="" className="hidden md:block md:w-2/5 lg:block" />
      </section>
      <section
        className="container pt-4 pb-8 md:pb-56 -mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 gap-x-10 gap-y-20 lg:gap-y-40"
        style={{ backgroundColor: "#0C024D" }}
      >
        <FeatureCard
          icon={listingsIcon}
          heading="Program listings"
          body="Create and update programs so those seeking help can find you when they need you the most."
          link="/organizations"
        />
        <FeatureCard
          icon={receiveIcon}
          heading="Receive referrals"
          body="Receive referrals from initiatives you belong to through users searching for assistance on SSN, and from custom forms you can share anywhere on the web."
          link="/initiatives"
        />
        <FeatureCard
          icon={trackIcon}
          heading="Track and manage cases"
          body="Update the status of an accepted referral and track all actions taken on that case. See a timeline of actions taken by various team members."
          link="/datalabs"
        />
        <FeatureCard
          icon={dashboardIcon}
          heading="Custom dashboards"
          body="Track the data that is important to you. Export the data to discover insights about your program."
          link="/datalabs"
        />
        <FeatureCard
          icon={shareIcon}
          heading="Schedule appointments "
          body="Set availability, schedule appointments, and see your calendar."
          link="/datalabs"
        />
        <FeatureCard
          icon={appointmentsIcon}
          heading="Team sharing"
          body="Work with various members of your team to maximize impact."
          link="/datalabs"
        />
        <FeatureCard
          icon={referralsIcon}
          heading="Auto screen referrals"
          body="Turn on autoscreen to automatically know who is eligible for your programs."
          link="/datalabs"
        />
        <FeatureCard
          icon={criteriaIcon}
          heading="Set eligibility criteria "
          body="Clearly state the requirements for individuals seeking assistance."
          link="/datalabs"
        />
        <FeatureCard
          icon={searchIcon}
          heading="Search"
          body="Users can search for social services by uploading their data and specifying their needs. Help is received through an easy match-making process."
          link="/search"
        />
      </section>
      <SliderStyle className="container overflow-x-hidden py-10 md:py-36">
        <div className="flex items-center justify-center mb-16">
          <TestimonialImageStyle
            onClick={() => setTestimonial(0)}
            active={testimonial === 0}
            className="mr-6"
          >
            <img src={testimonial_1_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle
            onClick={() => setTestimonial(1)}
            active={testimonial === 1}
            className="mr-6"
          >
            <img src={testimonial_2_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle onClick={() => setTestimonial(2)} active={testimonial === 2}>
            <img src={testimonial_3_Img} alt="" className="" />
          </TestimonialImageStyle>
        </div>
        <div className="slide-holder">
          <TransitionGroup className="slide-holder">
            {testimonial === 0 && (
              <CSSTransition timeout={500} classNames="slide">
                <Testimonial
                  body={testimonials[0].body}
                  author={testimonials[0].author}
                  position={testimonials[0].position}
                />
              </CSSTransition>
            )}
            {testimonial === 1 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[1].body}
                  author={testimonials[1].author}
                  position={testimonials[1].position}
                />
              </CSSTransition>
            )}
            {testimonial === 2 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[2].body}
                  author={testimonials[2].author}
                  position={testimonials[2].position}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </SliderStyle>
      <CtaSection />
      <Footer />
    </main>
  );
};

OrganizationsPage.propTypes = {};

export default OrganizationsPage;
