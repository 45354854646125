import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { TextLink, H5, H6 } from "./Texts";
// import { HeaderButton, OutlineButton } from "./Buttons";
import { Link } from "react-router-dom";
import t from "prop-types";
import * as Icon from "react-feather";

// import profileImg from "../assets/profile-img.jpg";
import navToggleLight from "../assets/menuLight.svg";
import navToggleDark from "../assets/menuDark.svg";
// import moonIcon from "../assets/ios-moon.svg";
// import sunIcon from "../assets/sunIcon.svg";
import logo from "../assets/logo.svg";
import logoDark from "../assets/logo-dark.svg";

// import { authContext } from "../store/AuthStore";
import { DrawerContext } from "../lib/drawerContext";
// import { themeContext } from "../lib/themeContext";
import Badge from "./Badge";

const HeaderStyle = styled.header`
  // width: 100vw;
  max-width: 100vw;
  // padding: 20px;
  padding-left: 96px;
  padding-right: 96px;
  z-index: 999;
  box-shadow: ${(props) => props.scrolled && "0px 1px 3px #3f3f4429"};
  transition: background 0.3s ease-in-out;

  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  background: ${(props) => props.bg};
`;

// const ThemeToggle = styled.button`
//   img {
//     width: 25px;
//     height: 27px;
//   }

//   &:focus {
//     border-color: #442ecf50;
//     outline-color: #442ecf50;
//     background: #442ecf10;
//   }
// `;

const MenuToggle = styled.button`
  img {
    width: 25px;
    height: 40px;
  }

  &:focus {
    border-color: #442ecf50;
    outline-color: #442ecf50;
    background: #442ecf10;
  }
`;

const Header = (props) => {
  const { toggleDrawer } = useContext(DrawerContext);

  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <HeaderStyle
      bg={isScrolled ? "#06002F" : "transparent"}
      className="fixed top-0 left-0 right-0 py-4 px-6 md:pt-8 md:pb-5 md:mx-auto flex justify-between items-center"
    >
      <div className="flex items-center">
        {/* LOGO */}
        {!isDark ? (
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        ) : (
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        )}
        <div className="hidden md:flex items-center ml-10">
          <Link to="/organizations" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              SSN for Organizations
            </TextLink>
          </Link>
          <Link to="/initiatives" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              Initiatives
            </TextLink>
          </Link>
          <Link to="/datalabs" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              Data Labs
            </TextLink>
          </Link>
          {/* <Link to="/search" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              Search
            </TextLink>
          </Link> */}
        </div>
      </div>

      {/* Right Navbar */}
      {/* <nav className="hidden md:flex justify-between items-center">
        <a href="/suggest" className="mr-6">
          <OutlineButton color="#ffffff" p="10px 24px" w="161px">
            Sign In
          </OutlineButton>
        </a>
        <a href="/createAccount" className="">
          <HeaderButton>Create Account</HeaderButton>
        </a>
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>
      </nav> */}

      {/* Mobile Navbar */}
      <nav className="flex md:hidden justify-between items-center">
        {/* <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle> */}

        <MenuToggle role="button" className="p-2" onClick={toggleDrawer}>
          <img src={navToggleLight} alt="" className="object-cover" />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};

export const SearchHeader = (props) => {
  const { toggleDrawer } = useContext(DrawerContext);

  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <HeaderStyle
      bg={isScrolled ? "#442ED0" : "transparent"}
      className="fixed top-0 left-0 right-0 pt-8 pb-5 mx-auto flex justify-between items-center"
    >
      <div className="flex items-center">
        {/* LOGO */}
        {!isDark ? (
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        ) : (
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        )}
        <div className="flex items-center ml-10">
          <Link to="/organizations" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              SSN for Organizations
            </TextLink>
          </Link>
          <Link to="/initiatives" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              Initiatives
            </TextLink>
          </Link>
          <Link to="/datalabs" className="mr-4">
            <TextLink color="#ffffff" fontSize="16px" p="9px 16px" className="rounded">
              Data Labs
            </TextLink>
          </Link>
          <Link to="/search" className="mr-4">
            <TextLink color="#442ED0" fontSize="16px" p="9px 16px" className="rounded bg-white">
              Search
            </TextLink>
          </Link>
        </div>
      </div>

      {/* Right Navbar */}
      {/* <nav className="hidden md:flex justify-between items-center">
        <a href="/suggest" className="mr-6">
          <OutlineButton color="#ffffff" p="10px 24px" w="161px">
            Sign In
          </OutlineButton>
        </a>
        <a href="/createAccount" className="">
          <HeaderButton bg="#fff" color="#442ED0">
            Create Account
          </HeaderButton>
        </a>
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>
      </nav> */}

      {/* Mobile Navbar */}
      <nav className="flex md:hidden justify-between items-center">
        {/* <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle> */}

        <MenuToggle role="button" className="p-2" onClick={toggleDrawer}>
          <img src={navToggleLight} alt="" className="object-cover" />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};

export const OrganizationHeader = (props) => {
  const { toggleDrawer } = useContext(DrawerContext);

  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <HeaderStyle
      bg={isScrolled ? "#fff" : "transparent"}
      scrolled={isScrolled}
      className="fixed top-0 left-0 right-0 pt-2 md:pt-8 md:pb-5 mx-auto flex justify-between items-center"
    >
      <div className="flex items-center">
        {/* LOGO */}
        {!isDark ? (
          <Link to="/">
            <img src={logoDark} alt="" />
          </Link>
        ) : (
          <Link to="/">
            <img src={logoDark} alt="" />
          </Link>
        )}
        <div className="hidden md:flex items-center ml-10">
          <Link to="/organizations" className="mr-4">
            <TextLink
              color="#ffffff"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
              style={{ backgroundColor: "#442ED0" }}
            >
              SSN for Organizations
            </TextLink>
          </Link>
          <Link to="/initiatives" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Initiatives
            </TextLink>
          </Link>
          <Link to="/datalabs" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Data Labs
            </TextLink>
          </Link>
          {/* <Link to="/search" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Search
            </TextLink>
          </Link> */}
        </div>
      </div>

      {/* Right Navbar */}
      {/* <nav className="hidden md:flex justify-between items-center">
        <a href="/suggest" className="mr-6">
          <OutlineButton color="#0C024D" p="10px 24px" w="161px">
            Sign In
          </OutlineButton>
        </a>
        <a href="/createAccount" className="">
          <HeaderButton>Create Account</HeaderButton>
        </a>
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>
      </nav> */}

      {/* Mobile Navbar */}
      <nav className="flex md:hidden justify-between items-center">
        {/* <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle> */}

        <MenuToggle role="button" className="p-2" onClick={toggleDrawer}>
          <img src={navToggleDark} alt="" className="object-cover" />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};

export const DataLabsHeader = (props) => {
  const { toggleDrawer } = useContext(DrawerContext);

  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <HeaderStyle
      bg={isScrolled ? "#fff" : "transparent"}
      scrolled={isScrolled}
      className="fixed top-0 left-0 right-0 pt-2 md:pt-8 md:pb-5 mx-auto flex justify-between items-center"
    >
      <div className="flex items-center">
        {/* LOGO */}
        {!isDark ? (
          <Link to="/">
            <img src={logoDark} alt="" />
          </Link>
        ) : (
          <Link to="/">
            <img src={logoDark} alt="" />
          </Link>
        )}
        <div className="hidden md:flex items-center ml-10">
          <Link to="/organizations" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              SSN for Organizations
            </TextLink>
          </Link>
          <Link to="initiatives" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Initiatives
            </TextLink>
          </Link>
          <Link to="/datalabs" className="mr-4">
            <TextLink
              style={{ backgroundColor: "#442ED0" }}
              color="#ffffff"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Data Labs
            </TextLink>
          </Link>
          {/* <Link to="/search" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Search
            </TextLink>
          </Link> */}
        </div>
      </div>

      {/* Right Navbar */}
      {/* <nav className="hidden md:flex justify-between items-center">
        <a href="/suggest" className="mr-6">
          <OutlineButton color="#0C024D" p="10px 24px" w="161px">
            Sign In
          </OutlineButton>
        </a>
        <a href="/createAccount" className="">
          <HeaderButton>Create Account</HeaderButton>
        </a>
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>
      </nav> */}

      {/* Mobile Navbar */}
      <nav className="flex md:hidden justify-between items-center">
        {/* <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle> */}

        <MenuToggle role="button" className="p-2" onClick={toggleDrawer}>
          <img src={navToggleDark} alt="" className="object-cover" />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};

export const InitiativesHeader = (props) => {
  const { toggleDrawer } = useContext(DrawerContext);

  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <HeaderStyle
      bg={isScrolled ? "#fff" : "transparent"}
      scrolled={isScrolled}
      className="fixed top-0 left-0 right-0 pt-2 md:pt-8 md:pb-5 mx-auto flex justify-between items-center"
    >
      <div className="flex items-center">
        {/* LOGO */}
        {!isDark ? (
          <Link to="/">
            <img src={logoDark} alt="" />
          </Link>
        ) : (
          <Link to="/">
            <img src={logoDark} alt="" />
          </Link>
        )}
        <div className="hidden md:flex items-center ml-10">
          <Link to="/organizations" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              SSN for Organizations
            </TextLink>
          </Link>
          <Link to="initiatives" className="mr-4">
            <TextLink
              color="#ffffff"
              style={{ backgroundColor: "#442ED0" }}
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Initiatives
            </TextLink>
          </Link>
          <Link to="/datalabs" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Data Labs
            </TextLink>
          </Link>
          {/* <Link to="/search" className="mr-4">
            <TextLink color="#0C024D" fontSize="16px" p="9px 16px" className="rounded">
              Search
            </TextLink>
          </Link> */}
        </div>
      </div>

      {/* Right Navbar */}
      {/* <nav className="hidden md:flex justify-between items-center">
        <a href="/suggest" className="mr-6">
          <OutlineButton color="#0C024D" p="10px 24px" w="161px">
            Sign In
          </OutlineButton>
        </a>
        <a href="/createAccount" className="">
          <HeaderButton>Create Account</HeaderButton>
        </a>
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>
      </nav> */}

      {/* Mobile Navbar */}
      <nav className="flex md:hidden justify-between items-center">
        {/* <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle> */}

        <MenuToggle role="button" className="p-2" onClick={toggleDrawer}>
          <img src={navToggleDark} alt="" className="object-cover" />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};

export const NotFoundHeader = (props) => {
  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <HeaderStyle
      bg={isScrolled ? "#fff" : "transparent"}
      scrolled={isScrolled}
      className="fixed top-0 left-0 right-0 pt-8 pb-5 mx-auto flex justify-center items-center"
    >
      {/* LOGO */}
      {!isDark ? (
        <Link to="/">
          <img src={logoDark} alt="" />
        </Link>
      ) : (
        <Link to="/">
          <img src={logoDark} alt="" />
        </Link>
      )}
    </HeaderStyle>
  );
};

export default Header;

const DashboardHeaderStyle = styled.header`
  width: 100%;
  background: #ffffff;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 1px 3px #3f3f4429;
  background: ${(props) => props.bg || "#ffffff"};
`;

export const DashboardHeader = ({ bg, className, title }) => {
  const { toggleDrawer } = useContext(DrawerContext);
  const { auth } = useContext(authContext);
  const { user } = auth;

  const [name, setName] = useState("");

  useEffect(() => {
    // if (auth.user && user.username) {
    setName(user.firstName + " " + user.lastName);
    // }
  }, [user]);

  return (
    <DashboardHeaderStyle
      bg={bg}
      className={`${className} flex justify-between items-center py-3 px-4`}
    >
      {/* right */}
      <div className="flex">
        <Link to="/dashboard">
          <img src={logo} alt="Moyalo" className="h-10" />
        </Link>
      </div>

      {/* left */}
      <div className="flex items-center">
        <div className="relative cursor-pointer">
          <Icon.Bell color="#BFC4D5" size={28} />
          <div className="flex h-3 w-3 absolute top-0 right-1">
            <div className="w-full h-full inline-flex rounded-full bg-blue-500 opacity-75 animate-ping absolute"></div>
            <div className="w-3 h-3 inline-flex relative rounded-full bg-blue-500 "></div>
          </div>
        </div>
        <div className="flex items-center ml-2 md:ml-4">
          <H5 color="#0D024E" className="capitalize hidden md:block">
            {name}
          </H5>
          <Badge
            bg="#BFC4D5"
            borderRadius="50%"
            className="ml-2 mr-1 flex items-center w-8 h-8 justify-center"
            p="0"
          >
            {/* <H6 fontSize="14px" color="#ffffff" className="uppercase">
                {user && user.firstName?.[0] + " " + user.lastName?.[0]}
              </H6> */}
            <H6 fontSize="12px" color="#ffffff" className="uppercase">
              {`${user?.firstName?.[0]} ${user?.lastName?.[0]}`}
            </H6>
            {/* <P fontWeight="500">
                    {`${user?.firstName} ${user?.lastName}`}
                  </P> */}
          </Badge>
          {/* <Icon.ChevronDown color="#BFC4D5" size={24} /> */}
          {/* <img
              src={profileImg}
              alt=""
              className="w-10 h-10 rounded-full object-cover"
            /> */}
        </div>
        <Icon.Menu
          color="#AEB3C4"
          size={24}
          className="lg:hidden ml-2 cursor-pointer"
          onClick={toggleDrawer}
        />
      </div>
    </DashboardHeaderStyle>
  );
};

DashboardHeader.propTypes = {
  bg: t.string,
  className: t.string,
  title: t.string,
};
