import React, { useState } from "react";
import t from "prop-types";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import * as Icon from "react-feather";

import heroImg from "../assets/datalabs-hero-img.png";
import searchIcon from "../assets/paper-search.svg";
import importIcon from "../assets/import.svg";
import transformIcon from "../assets/transform.svg";
import analyzeIcon from "../assets/analyze.svg";
import visualizeIcon from "../assets/Initiatives.svg";
import exportIcon from "../assets/export.svg";
import machineIcon from "../assets/machine.svg";
import testimonial_1_Img from "../assets/testimonial-1.png";
import testimonial_2_Img from "../assets/testimonial-2.png";
import testimonial_3_Img from "../assets/testimonial-3.png";

import { DataLabsHeader } from "../components/Headers";
import { BigP, H1, H2, H3, H4, P, SmallP } from "../components/Texts";
import { OutlineButton, PrimaryButton } from "../components/Buttons";
// import { Link } from "react-router-dom";
import Footer from "../components/Footers";
import CtaSection from "../components/CtaSection";
import Drawer from "../components/Drawer";

const Hero = styled.section`
  background: #ffffff;
  // height: 100vh;
  min-height: 100vh;
  // max-height: 100vh;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

// const HeroImage = styled.img`
//   transform: translateX(-8px);
//   // margin-bottom: -20em;
//   height: 100vh;
//   // object-fit: none;
// `;

const FeatureCardStyle = styled.div`
  @media (max-width: 600px) {
    img {
      width: 54px;
      height: 56px;
    }
  }
`;

const FeatureCard = ({ icon, heading, body, link }) => (
  <FeatureCardStyle className="flex flex-col justify-between h-full">
    <div>
      <img src={icon} alt="" className="h-20 mb-8" />
      <H3 className="mb-6" color="#fff">
        {heading}
      </H3>
      <P fontSize="18px" className="mb-6" color="#CECBE2">
        {body}
      </P>
    </div>
    {/* <Link to={link}>
      <TextLink color="#fff" fontFamily="medium" className="flex items-center">
        Learn more
        <Icon.ArrowRight color="#fff" size={16} className="ml-4" />
      </TextLink>
    </Link> */}
  </FeatureCardStyle>
);

FeatureCard.propTypes = {
  icon: t.string,
  heading: t.string.isRequired,
  body: t.string.isRequired,
  link: t.string,
};

const SliderStyle = styled.section`
  max-width: 100vh;

  .slide-holder {
    min-height: 415px;
  }

  .slide-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: all 500ms ease;
  }
  .slide-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 500ms ease;
  }
`;

const TestimonialImageStyle = styled.div`
  width: 85px;
  height: 85px;

  @media (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid ${(props) => (props.active ? "#7D6BF1" : "transparent")};
`;

const testimonials = [
  {
    body: "“Social Safety has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who trains many design teams, this makes social safety a clear choice as the industry-standard tool for track and manage cases.”",
    author: "Silvia Bormüller",
    position: "CEO Trainnex",
  },
  {
    body: "“I founded Social Safety Net to be the bridge between good intentions and meaningful outcomes. We can unlock new impact by making finding help as easy as finding lunch.”",
    author: "Julia Muld",
    position: "CEO Pharmatex",
  },
  {
    body: "“Social Safety has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who trains many design teams, this makes social safety a clear choice as the industry-standard tool for track and manage cases.”",
    author: "Kelvin Walsh",
    position: "CTO Lista",
  },
];

const Testimonial = ({ body, author, position }) => (
  <div>
    <BigP
      fontSize="40px"
      smallSize="24px"
      fontFamily="regular"
      lineHeight="48px"
      className="text-center mx-auto md:w-3/4 mb-12"
      color="#442ED0"
    >
      {body}
    </BigP>
    <P fontSize="24px" fontFamily="medium" className="text-center mb-4" color="#0C024D">
      {author}
    </P>
    <SmallP color="#393648" fontSize="16px" className="text-center">
      {position}
    </SmallP>
  </div>
);

Testimonial.propTypes = {
  body: t.string.isRequired,
  author: t.string.isRequired,
  position: t.string.isRequired,
};

const DataLabsPage = (props) => {
  const [testimonial, setTestimonial] = useState(0);

  return (
    <main>
      <DataLabsHeader />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="max-h-screen w-full flex justify-between items-center flex-wrap-reverse lg:mt-28 lg:mb-10">
        <div className="text-center lg:text-left pl-4 pr-4 md:pr-0 lg:pl-24 lg:pr-4 self-end pt-10 lg:pt-32 lg:w-1/2 md:mx-auto md:w-8/12 flex flex-col lg:mb-10">
          <H1 color="#442ED0" className="mb-10 lg:w-full" mediumSize="45px" smallSize="32px">
            A singular place for actionable data.
          </H1>
          <H4
            color="#0C024D"
            fontFamily="regular"
            className="mb-10 lg:w-5/6 md:w-2/3 md:mx-auto lg:mx-0"
            fontSize="18px"
          >
            We enable innovators to build new products and services on our APIS, or to become
            citizen data scientist in only a few clicks.
          </H4>
          <div className="md:flex md:space-x-4 md:space-x-0 justify-center lg:justify-start ">
            <a
              href="https://datalabs.socialsafety.net/login"
              target="_blank"
              rel="noopener noreferrer"
              className="md:mr-6 mb-4 md:mb-0 block"
            >
              <OutlineButton
                color="#442ED0"
                p="18px 24px"
                w="161px"
                smallW="312px"
                hoverBg="#EEEEEE"
                hoverColor="#442ED0"
              >
                Sign In
              </OutlineButton>
            </a>
            <a
              href="https://datalabs.socialsafety.net/register"
              target="_blank"
              rel="noopener noreferrer"
              className="md:mr-6"
            >
              <PrimaryButton p="18px 24px" w="161px" smallW="312px">
                Create Account
              </PrimaryButton>
            </a>
          </div>
        </div>
        <div className="hidden lg:block lg:w-1/2 lg:pt-10 self-end lg:pl-4">
          <img src={heroImg} alt="" className="" />
        </div>
      </Hero>
      {/* What is social safety net */}
      <section className="container py-10 md:py-24 -mt-12 " style={{ backgroundColor: "#0C024D" }}>
        <div className="md:mr-10 md:text-center">
          <H2
            color="#ffffff"
            fontSize="50px"
            fontFamily="semi"
            lineHeight="64px"
            className="text-center md:w-4/5 mx-auto md:mb-10"
          >
            We’re in the business of finding and freeing relevant data necessary to power public
            good.
          </H2>
        </div>
      </section>
      <section
        className="container py-10 md:pb-56 -mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-20 lg:gap-y-40"
        style={{ backgroundColor: "#0C024D" }}
      >
        <FeatureCard
          icon={searchIcon}
          heading="Search for data"
          body="Find accurate and updated data on various topics from migration to internet phone subscriptions."
          link="/organizations"
        />
        <FeatureCard
          icon={importIcon}
          heading="Import data"
          body="Import data as CSV, JSON or APIs. Take control of who gets to see that data by setting visibility to private or public."
          link="/initiatives"
        />
        <FeatureCard
          icon={transformIcon}
          heading="Transform data"
          body="Keep the rows and columns you need, change data format, and clean your data with ease."
          link="/datalabs"
        />
        <FeatureCard
          icon={analyzeIcon}
          heading="Analyze"
          body="Run simple or complex statistical analysis all with the click of a button."
          link="/datalabs"
        />
        <FeatureCard
          icon={visualizeIcon}
          heading="Visualize "
          body="Use SSN’s built in visualization tools to create shareable custom dashboards or export data to your preferred visualization software."
          link="/datalabs"
        />
        <FeatureCard
          icon={exportIcon}
          heading="Export"
          body="Export data in any file format of your choice."
          link="/datalabs"
        />
        <FeatureCard
          icon={machineIcon}
          heading="Machine learning"
          body="Access the ML algorithms of your choice. We have all of them!"
          link="/datalabs"
        />
      </section>
      <SliderStyle className="container overflow-x-hidden py-16 md:py-36 ">
        <div className="flex items-center justify-center mb-10 md:mb-16">
          <TestimonialImageStyle
            onClick={() => setTestimonial(0)}
            active={testimonial === 0}
            className="mr-6"
          >
            <img src={testimonial_1_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle
            onClick={() => setTestimonial(1)}
            active={testimonial === 1}
            className="mr-6"
          >
            <img src={testimonial_2_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle onClick={() => setTestimonial(2)} active={testimonial === 2}>
            <img src={testimonial_3_Img} alt="" className="" />
          </TestimonialImageStyle>
        </div>
        <div className="slide-holder">
          <TransitionGroup className="slide-holder">
            {testimonial === 0 && (
              <CSSTransition timeout={500} classNames="slide">
                <Testimonial
                  body={testimonials[0].body}
                  author={testimonials[0].author}
                  position={testimonials[0].position}
                />
              </CSSTransition>
            )}
            {testimonial === 1 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[1].body}
                  author={testimonials[1].author}
                  position={testimonials[1].position}
                />
              </CSSTransition>
            )}
            {testimonial === 2 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[2].body}
                  author={testimonials[2].author}
                  position={testimonials[2].position}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </SliderStyle>
      <CtaSection />
      <Footer />
    </main>
  );
};

DataLabsPage.propTypes = {};

export default DataLabsPage;
