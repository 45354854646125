import React, { useState } from "react";
import t from "prop-types";
import styled from "styled-components";
// import * as Icon from "react-feather";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import heroImg from "../assets/initiatives-hero-image.png";
import checkIcon from "../assets/check.svg";
import testimonial_1_Img from "../assets/testimonial-1.png";
import testimonial_2_Img from "../assets/testimonial-2.png";
import testimonial_3_Img from "../assets/testimonial-3.png";

import { InitiativesHeader } from "../components/Headers";
import { BigP, H1, H2, H3, H4, P, SmallP } from "../components/Texts";
// import { OutlineButton, PrimaryButton } from "../components/Buttons";
// import { Link } from "react-router-dom";
import Footer from "../components/Footers";
import CtaSection from "../components/CtaSection";
// import SearchForm from "../components/SearchForm";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { BasicInput, BasicTextArea } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import Drawer from "../components/Drawer";

const Hero = styled.section`
  background: #fff;
  // height: 100vh;
  min-height: 100vh;
  // max-height: 100vh;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const SliderStyle = styled.section`
  max-width: 100vh;

  .slide-holder {
    min-height: 415px;
  }

  .slide-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: all 500ms ease;
  }
  .slide-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 500ms ease;
  }
`;

const TestimonialImageStyle = styled.div`
  width: 85px;
  height: 85px;

  @media (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid ${(props) => (props.active ? "#7D6BF1" : "transparent")};
`;

const testimonials = [
  {
    body:
      "“Social Safety has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who trains many design teams, this makes social safety a clear choice as the industry-standard tool for track and manage cases.”",
    author: "Silvia Bormüller",
    position: "CEO Trainnex",
  },
  {
    body:
      "“I founded Social Safety Net to be the bridge between good intentions and meaningful outcomes. We can unlock new impact by making finding help as easy as finding lunch.”",
    author: "Julia Muld",
    position: "CEO Pharmatex",
  },
  {
    body:
      "“Social Safety has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who trains many design teams, this makes social safety a clear choice as the industry-standard tool for track and manage cases.”",
    author: "Kelvin Walsh",
    position: "CTO Lista",
  },
];

const Testimonial = ({ body, author, position }) => (
  <div>
    <BigP
      fontSize="40px"
      smallSize="24px"
      fontFamily="regular"
      lineHeight="48px"
      className="text-center mx-auto md:w-3/4 mb-12"
      color="#442ED0"
    >
      {body}
    </BigP>
    <P fontSize="24px" fontFamily="medium" className="text-center mb-4" color="#0C024D">
      {author}
    </P>
    <SmallP color="#393648" fontSize="16px" className="text-center">
      {position}
    </SmallP>
  </div>
);

Testimonial.propTypes = {
  body: t.string.isRequired,
  author: t.string.isRequired,
  position: t.string.isRequired,
};

const CheckList = ({ texts }) => (
  <>
    {texts.map((text, idx) => (
      <div key={idx} className="flex items-center mb-6">
        <img src={checkIcon} alt="" className="mr-6" />
        <P fontSize="24px" className="text-left" color="#fff">
          {text}
        </P>
      </div>
    ))}
  </>
);

CheckList.propTypes = {
  texts: t.array.isRequired,
};

const InitiativesPage = () => {
  const [testimonial, setTestimonial] = useState(0);

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  const submitDetails = (data) => console.log(data);

  return (
    <main>
      <InitiativesHeader />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="w-full flex justify-between items-center flex-wrap-reverse pt-32 pb-10">
        <div className="text-center mx-auto lg:text-left pl-4 pr-4 md:pr-0 lg:pr-4 lg:pl-20 self-center pt-10 md:mx-auto lg:w-1/2 flex flex-col justify-center">
          <H1
            color="#442ED0"
            className="mb-10 w-11/12 mx-auto md:mx-0"
            smallSize="32px"
            mediumSize="45px"
          >
            “Home Base” for all the moving parts of your program.
          </H1>
          <H4
            color="#0C024D"
            fontFamily="regular"
            className="text-center lg:text-left md:text-center md:pr-8 md:w-2/3 md:mx-auto lg:w-full mb-10"
            fontSize="18px"
            smallSize="16px"
          >
            Whether it is powering your CSR initiative, or making sense of your data, whatever you
            need, we have you covered.
          </H4>
        </div>
        <div className="hidden md:mx-auto lg:block lg:w-1/2 self-end lg:pl-4 md:pt-10">
          <img src={heroImg} alt="" className="w-full" />
        </div>
      </Hero>
      {/* tell us form */}
      <section
        className="container grid lg:grid-cols-2 gap-10 pt-8 md:pt-32 pb-0 md:py-32"
        style={{ backgroundColor: "#0C024D" }}
      >
        <div className="text-center lg:text-left">
          <H2
            color="#fff"
            fontSize="50px"
            fontFamily="semi"
            lineHeight="64px"
            className="md:w-5/6 mb-5 mx-auto lg:mx-0"
          >
            Tell us a bit about yourself, and we’ll tell you a lot more about us.
          </H2>
          <H4
            color="#B4B0D1"
            fontFamily="regular"
            className="md:w-5/6 mb-24 md:mx-auto lg:mx-0"
            fontSize="18px"
          >
            Let us know your goals and we’ll help you achieve them!
          </H4>
          <div className="md:pt-10 lg:pt-32 md:pb-10 mb-10 md:w-10/12 lg:w-full md:mx-auto md:pl-10 lg:pl-0 lg:mx-0">
            <CheckList
              texts={[
                "Enterprise-grade security",
                "Advanced user management",
                "Unlimited potential collaborate",
                "Unlimited potential for impact",
              ]}
            />
          </div>
        </div>
        <form
          onSubmit={handleSubmit(submitDetails)}
          className="bg-white md:rounded md:shadow p-6 md:p-10 -mx-6 md:mx-0"
        >
          <H3 color="#0C024D" fontSize="28px" className="mb-10 leading-10 text-center">
            Tell us a bit about yourself, and we’ll tell you a lot more about us.
          </H3>
          <div className="mb-6">
            <BasicInput
              type="text"
              id="firstname"
              name="firstname"
              ref={register({
                required: "Please enter your first name.",
              })}
              className="w-full"
              placeholder="Your first name"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="firstname"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => <P key={type}>{message}</P>)
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicInput
              type="text"
              id="lastname"
              name="lastname"
              ref={register({
                required: "Please enter your last name.",
              })}
              className="w-full"
              placeholder="Your last name"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="lastname"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => <P key={type}>{message}</P>)
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicInput
              type="email"
              id="email"
              name="email"
              ref={register({
                required: "Please enter your email.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter a valid e-mail address",
                },
              })}
              className="w-full"
              placeholder="Work email"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => <P key={type}>{message}</P>)
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicInput
              type="text"
              id="company_name"
              name="company_name"
              ref={register({
                required: "Please enter your company name.",
              })}
              className="w-full"
              placeholder="Company name"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="company_name"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => <P key={type}>{message}</P>)
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicInput
              type="text"
              id="phone"
              name="phone"
              ref={register({
                required: "Please enter your phone number.",
                pattern: {
                  value: /^[\+]*[0-9]+$/,
                  message: "Enter a valid phone number",
                },
                minLength: {
                  value: 10,
                  message: "Enter a valid phone number",
                },
              })}
              className="w-full"
              placeholder="Phone number"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => <P key={type}>{message}</P>)
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <label htmlFor="solving">
              <P color="#442ed0" className="mb-4" fontFamily="medium">
                What are you solving for?
              </P>
            </label>
            <BasicTextArea
              rows="4"
              id="solving"
              name="solving"
              ref={register({
                required: "Please enter what you solve.",
              })}
              className="w-full"
              placeholder="Your answer..."
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="solving"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => <P key={type}>{message}</P>)
              }
            </ErrorMessage>
          </div>

          <PrimaryButton w="170px" smallW="100%" className="block">
            Submit
          </PrimaryButton>
        </form>
      </section>
      <SliderStyle className="container bg-white overflow-x-hidden py-16 md:py-36 -mt-4 md:mt-0">
        <div className="flex items-center justify-center mb-10 md:mb-16">
          <TestimonialImageStyle
            onClick={() => setTestimonial(0)}
            active={testimonial === 0}
            className="mr-6"
          >
            <img src={testimonial_1_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle
            onClick={() => setTestimonial(1)}
            active={testimonial === 1}
            className="mr-6"
          >
            <img src={testimonial_2_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle onClick={() => setTestimonial(2)} active={testimonial === 2}>
            <img src={testimonial_3_Img} alt="" className="" />
          </TestimonialImageStyle>
        </div>
        <div className="slide-holder">
          <TransitionGroup className="slide-holder">
            {testimonial === 0 && (
              <CSSTransition timeout={500} classNames="slide">
                <Testimonial
                  body={testimonials[0].body}
                  author={testimonials[0].author}
                  position={testimonials[0].position}
                />
              </CSSTransition>
            )}
            {testimonial === 1 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[1].body}
                  author={testimonials[1].author}
                  position={testimonials[1].position}
                />
              </CSSTransition>
            )}
            {testimonial === 2 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[2].body}
                  author={testimonials[2].author}
                  position={testimonials[2].position}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </SliderStyle>
      <CtaSection />
      <Footer />
    </main>
  );
};

InitiativesPage.propTypes = {};

export default InitiativesPage;
