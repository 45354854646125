import React, { useState } from "react";
import { Link } from "react-router-dom";
import t from "prop-types";
import * as Icon from "react-feather";
import styled from "styled-components";

import { H4, P } from "./Texts";

const FooterSection = ({ heading, subLinks }) => (
  <div className="mr-16">
    <H4 color="#333" className="mb-6">
      {heading}
    </H4>
    {subLinks.map((link, idx) => (
      <React.Fragment key={idx}>
        {link.external ? (
          <a key={idx} href={link.to} target="_blank" rel="noreferrer" className="block mb-4">
            <P fontSize="16px" color="#828282">
              {link.name}
            </P>
          </a>
        ) : (
          <Link key={idx} to={link.to} className="block mb-4">
            <P fontSize="16px" color="#828282">
              {link.name}
            </P>
          </Link>
        )}
      </React.Fragment>
    ))}
  </div>
);

FooterSection.propTypes = {
  heading: t.string.isRequired,
  subLinks: t.array,
};

const FooterHeader = styled.div`
  border-bottom: 1px solid #e6e5f1;

  > svg {
    transition: all ease 0.3s;
    transform: ${(props) => (props.isExpanded ? "rotate(180deg)" : "rotate(0)")};
  }
`;

const CollapsibleFooterSection = ({ heading, subLinks }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div className="">
      <FooterHeader
        className="flex justify-between pb-4 mb-6"
        isExpanded={expanded}
        onClick={toggleExpanded}
      >
        <H4 color="#333" className="">
          {heading}
        </H4>
        <Icon.ChevronDown size={16} color="#9390B0" />
      </FooterHeader>
      {expanded && (
        <>
          {subLinks.map((link, idx) => (
            <React.Fragment key={idx}>
              {link.external ? (
                <a key={idx} href={link.to} target="_blank" rel="noreferrer" className="block mb-4">
                  <P fontSize="16px" color="#828282">
                    {link.name}
                  </P>
                </a>
              ) : (
                <Link key={idx} to={link.to} className="block mb-4">
                  <P fontSize="16px" color="#828282">
                    {link.name}
                  </P>
                </Link>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

CollapsibleFooterSection.propTypes = {
  heading: t.string.isRequired,
  subLinks: t.array,
};

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="container">
      <div className="space-y-10 md:space-y-0 hidden md:flex">
        {/* Product */}
        <FooterSection
          heading="Product"
          subLinks={[
            {
              name: "SSN for Organizations",
              to: "/organizations",
            },
            {
              name: "Initiatives",
              to: "/initiatives",
            },
            {
              name: "Data Labs",
              to: "/datalabs",
            },
            // {
            //   name: "Search",
            //   to: "/search",
            // },
          ]}
        />
        <FooterSection
          heading="Company"
          subLinks={[
            {
              name: "About us",
              to: "/about",
            },
            {
              name: "Careers",
              to: "/careers",
            },
            {
              name: "Press",
              to: "/press",
            },
          ]}
        />
        <FooterSection
          heading="Legal"
          subLinks={[
            {
              name: "Terms of Service",
              to: "/terms",
            },
            {
              name: "Privacy Policy",
              to: "/privacy",
            },
          ]}
        />
        <FooterSection
          heading="Follow us"
          subLinks={[
            {
              name: "Twitter",
              to: "https://twitter.com",
              external: true,
            },
            {
              name: "Instagram",
              to: "https://instagram.com",
              external: true,
            },
            {
              name: "Facebook",
              to: "https://facebook.com",
              external: true,
            },
            {
              name: "LinkedIn",
              to: "https://linkedin.com",
              external: true,
            },
          ]}
        />
      </div>

      <div className="space-y-10 md:space-y-0 md:hidden">
        {/* Product */}
        <CollapsibleFooterSection
          heading="Product"
          subLinks={[
            {
              name: "SSN for Organizations",
              to: "/organizations",
            },
            {
              name: "Initiatives",
              to: "/initiatives",
            },
            {
              name: "Data Labs",
              to: "/datalabs",
            },
            // {
            //   name: "Search",
            //   to: "/search",
            // },
          ]}
        />
        <CollapsibleFooterSection
          heading="Company"
          subLinks={[
            {
              name: "About us",
              to: "/about",
            },
            {
              name: "Careers",
              to: "/careers",
            },
            {
              name: "Press",
              to: "/press",
            },
          ]}
        />
        <CollapsibleFooterSection
          heading="Legal"
          subLinks={[
            {
              name: "Terms of Service",
              to: "/terms",
            },
            {
              name: "Privacy Policy",
              to: "/privacy",
            },
          ]}
        />
        <CollapsibleFooterSection
          heading="Follow us"
          subLinks={[
            {
              name: "Twitter",
              to: "https://twitter.com",
              external: true,
            },
            {
              name: "Instagram",
              to: "https://instagram.com",
              external: true,
            },
            {
              name: "Facebook",
              to: "https://facebook.com",
              external: true,
            },
            {
              name: "LinkedIn",
              to: "https://linkedin.com",
              external: true,
            },
          ]}
        />
      </div>

      <P className="mt-10 mb-6" color="#828282" fontSize="16px">
        Copyright © Social Security Net {year}, All rights reserved.
      </P>
    </footer>
  );
};

export default Footer;
